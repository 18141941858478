import {colors} from '../../styles/colors';
import {Box, Button, Divider, Typography} from '@mui/material';
import {useStateContext} from '../../state/StateContext';
import {useState} from "react";
import {PRIMARY_FONT_SIZE} from "../../config/constants";

function SourcesButton({ sources }) {
  const { setCameraSpeed, activeImageIndex,
    setIsOpenDrawer, setDrawerTitle, setDrawerContent } = useStateContext();
  const [isHovered, setIsHovered] = useState(false);
  const isOnReset = activeImageIndex === null;

  function openDrawer() {
    setIsOpenDrawer(true);
    setDrawerTitle('Πηγές');
    setDrawerContent(sources);
    setCameraSpeed(0.5);
  }

  if (!isOnReset) return <></>;

  return (
    <Box textAlign="end" sx={{position: "absolute", bottom: 30, right: 35}}>
      <Button id="next" onClick={openDrawer} disableRipple
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
              sx={{
                px: 0.25, m: 0, pb: 0.25,
                fontSize: PRIMARY_FONT_SIZE,
                color: colors.white,
                backgroundColor: 'transparent',
                '&.MuiButtonBase-root:hover': {
                  bgcolor: 'transparent',
                },
              }}>
        <Typography variant="title" color="inherit" noWrap>
          {'πηγές'}
        </Typography>
      </Button>
      <Divider
        style={{
          width: isHovered ? '65%' : '0',
          transition: 'width 0.4s ease',
          transform: 'translate(-50%, 0%)'}}
        sx={{backgroundColor: colors.white, ml: '50%'}}/>
    </Box>
  );
}

export default SourcesButton;