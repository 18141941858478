import * as THREE from 'three';
import {Text, useCursor} from '@react-three/drei';
import AkzidenzGroteskProRegular from '../../assets/fonts/AkzidenzGroteskPro-Regular.ttf';
import AkzidenzGroteskProBold from '../../assets/fonts/AkzidenzGroteskPro-Bold.ttf';
import {useStateContext} from '../../state/StateContext';
import {useRef, useState} from 'react';
import {colors} from "../../styles/colors";
import Line from "./Line";
import {useFrame} from "@react-three/fiber";
import {easing} from "maath";

function FrameCaption({ image }) {
  const { setIsOpenDrawer, setDrawerTitle, setDrawerContent } = useStateContext();
  const [isPointerCursor, setIsPointerCursor] = useState(false);
  const [titleHeight, setTitleHeight] = useState(null);
  const aspectRatio = image.width / image.height;
  const isPortraitImage = aspectRatio <= 2.0;
  const rightLineRef = useRef();
  const leftLineRef = useRef();

  useCursor(isPointerCursor);

  useFrame((state, dt) => {
    rightLineRef.current.visible = isPointerCursor;
    leftLineRef.current.visible = isPointerCursor;

    easing.damp3(rightLineRef.current.position,[isPointerCursor ? 0.09 : 0.05, 0, 0],0.2, dt);
    easing.damp3(leftLineRef.current.position,[isPointerCursor ? -0.1 : -0.05, 0, 0],0.2, dt);
  });

  function calculateTitlePosition() {
    if (isPortraitImage) return [aspectRatio*0.9, 1.5, 0];
    return [aspectRatio*0.15, 1.5, 0];
  }

  function calculateTitleMaxWidth() {
    if (isPortraitImage) return 0.5;
    return 1.0;
  }

  // TODO decide
  function calculateOpenButtonPosition() {
    // Open button below title
    if (isPortraitImage) return [aspectRatio*0.9, 1.5-titleHeight-0.05, 0];
    return [aspectRatio*0.4, 0.15, 0];

    // Open button on bottom
    // if (isPortraitImage) return [aspectRatio*0.9, 0.1, 0];
    // return [aspectRatio*0.4, 0.15, 0];
  }

  function openDrawer() {
    setIsOpenDrawer(true);
    setDrawerTitle(image.title);
    setDrawerContent(image.description);
  }


  // TODO decide
  function calculateLineGeometry() {
    const points = [];

    if (isPortraitImage) {
      // Line below title
      points.push(new THREE.Vector3(aspectRatio * 0.9, 1.5-titleHeight-0.025, 0))
      points.push(new THREE.Vector3(aspectRatio * 0.9 + calculateTitleMaxWidth(), 1.5-titleHeight-0.025, 0))

      // Line on bottom
      // points.push(new THREE.Vector3(aspectRatio * 0.9, 0.0375, 0))
      // points.push(new THREE.Vector3(aspectRatio * 0.9 + 0.25, 0.0375, 0))
    }
    else {
      points.push(new THREE.Vector3(aspectRatio * 0.35, 0.175, 0))
      points.push(new THREE.Vector3(aspectRatio * 0.4 + 0.2125, 0.175, 0))
    }

    return new THREE.BufferGeometry().setFromPoints(points);
  }

  function calculateRightLineGeometry() {
    const points = [];
    if (isPortraitImage) {
      points.push(new THREE.Vector3(aspectRatio * 0.9, 1.5-titleHeight-0.115, 0));
      points.push(new THREE.Vector3(aspectRatio * 0.9 + 0.0925, 1.5-titleHeight-0.115, 0));
    }
    else {
      points.push(new THREE.Vector3(aspectRatio * 0.4, 0.0875, 0));
      points.push(new THREE.Vector3(aspectRatio * 0.4 + 0.095, 0.0875, 0));
    }
    return new THREE.BufferGeometry().setFromPoints(points);
  }

  function calculateLeftLineGeometry() {
    const points = [];
    if (isPortraitImage) {
      points.push(new THREE.Vector3(aspectRatio*0.9 + 0.1 + 0.1, 1.5-titleHeight-0.115, 0));
      points.push(new THREE.Vector3(aspectRatio*0.9 + 0.1 , 1.5-titleHeight-0.115, 0));
    }
    else {
      points.push(new THREE.Vector3(aspectRatio * 0.4 + 0.08 + 0.1175, 0.0875, 0));
      points.push(new THREE.Vector3(aspectRatio * 0.4 + 0.1, 0.0875, 0));
    }
    return new THREE.BufferGeometry().setFromPoints(points);
  }


  return (<>
    <Text identifier="title"
          maxWidth={calculateTitleMaxWidth()}
          position={calculateTitlePosition()}
          onSync={(mesh)=> {setTitleHeight(Math.abs(mesh.geometry.boundingBox.min.y))}}
          anchorX="left" anchorY="top"
          fontSize={0.036}
          font={AkzidenzGroteskProBold}>
      {image.title.toLowerCase()}
    </Text>
    {(titleHeight !== null && isPortraitImage) && <Line geometry={calculateLineGeometry()} color={colors.white} />}
    {titleHeight !== null && <Text identifier="open"
          position={calculateOpenButtonPosition()}
          anchorX="left" anchorY="top"
          fontSize={0.034}
          onClick={openDrawer}
          onPointerOver={(e) => (e.stopPropagation(), setIsPointerCursor(true))}
          onPointerOut={() => setIsPointerCursor(false)}
          font={AkzidenzGroteskProRegular}>
      {'περισσότερα'}
    </Text>}
    <Line innerRef={rightLineRef} geometry={calculateRightLineGeometry()} color={colors.white} />
    <Line innerRef={leftLineRef} geometry={calculateLeftLineGeometry()} color={colors.white}/>
  </>)
}

export default FrameCaption;