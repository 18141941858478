import {useRef, useState} from 'react';
import {useRoute} from 'wouter';
import getUuid from 'uuid-by-string';
import {Image, useCursor} from '@react-three/drei';
import {useFrame} from '@react-three/fiber';
import {easing} from 'maath';
import {colors} from "../../styles/colors";
import {useStateContext} from "../../state/StateContext";
import FrameCaption from './FrameCaption';

function Frame({ ...image }) {
  const { exhibition, isOpenDrawer } = useStateContext();
  const imageRef = useRef();
  const innerFrameRef = useRef();
  const outerFrameRef = useRef();

  const [, route] = useRoute(`/${exhibition.route}/:id`);
  const [isPointerCursor, setIsPointerCursor] = useState(false);
  const [rnd] = useState(() => Math.random());
  const name = getUuid(image.url);

  const isActive = route?.id === name;
  const url = require(`../../content${image.url}`);
  const aspectRatio = image.width / image.height;
  const isPortraitImage = aspectRatio <= 2.0;

  const xPositionFactor = 1;
  const zPositionFactor = 1.25;
  const yRotateFactor = 1;

  useCursor(isPointerCursor);

  useFrame((state, dt) => {
    // playZoomAnimation(state);
    renderFrame(dt);
  });

  function playZoomAnimation(state) {
    // TODO improve zoom out animation when selected/unselected
    const zoom = 1 + Math.abs(Math.sin( rnd * 10000 + state.clock.elapsedTime / 2) / 12);
    if (isActive) imageRef.current.material.zoom = 1;
    else imageRef.current.material.zoom = zoom;
  }

  function renderFrame(dt) {
    easing.damp3(
      imageRef.current.scale,
      [0.85 * (!isActive && isPointerCursor ? 0.85 : 1), 0.9 * (!isActive && isPointerCursor ? 0.905 : 1), 1],
      0.1,
      dt)
    // The following line adds highlight color on hover
    easing.dampC(
      innerFrameRef.current.material.color,
      isPointerCursor ? colors.ecru : colors.white,
      0.1,
      dt)
  }

  function calculateFrameScale() {
    const meshScale = 0.8;
    if (isPortraitImage) return [aspectRatio * 2 * meshScale, 2 * meshScale, 0.05]
    return [aspectRatio * 1.3 * meshScale, 1.3 * meshScale, 0.05];
  }

  function calculateImageScale() {
    return [aspectRatio, 1];
  }

  function calculateFramePosition() {
    return [
      image.position[0] * xPositionFactor,
      image.position[1],
      image.position[2] * zPositionFactor ,
    ];
  }

  function calculateFrameRotation() {
    return [
      image.rotation[0],
      image.rotation[1] * yRotateFactor,
      image.rotation[2],
    ];
  }


  return (
    <group position={calculateFramePosition()} rotation={calculateFrameRotation()}>
      <mesh
        name={name}
        ref={outerFrameRef}
        onPointerOver={(e) => (e.stopPropagation(), setIsPointerCursor(true))}
        onPointerOut={() => setIsPointerCursor(false)}
        scale={calculateFrameScale()}
        position={[0, 0.8, 0]}
      >
        <boxGeometry />
        <meshStandardMaterial color={colors.night} metalness={0.5} roughness={0.5} envMapIntensity={2} />
        <mesh ref={innerFrameRef} raycast={() => null} scale={[0.9, 0.93, 0.9]} position={[0, 0, 0.2]}>
          <boxGeometry />
          <meshBasicMaterial toneMapped={false} fog={false} />
        </mesh>
        <Image
          ref={imageRef}
          url={url}
          raycast={() => null}
          scale={calculateImageScale()}
          position={[0, 0, 0.7]}
          zoom={1}
        />
      </mesh>
      {(isActive && !isOpenDrawer) && <FrameCaption image={image} />}
    </group>
  )
}

export default Frame;