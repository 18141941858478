import AkzidenzGroteskProRegular from '../../assets/fonts/AkzidenzGroteskPro-Regular.ttf';
import {Text, useCursor} from '@react-three/drei';
import {useRef, useState} from 'react';
import {useStateContext} from '../../state/StateContext';
import * as THREE from "three";
import {colors} from "../../styles/colors";
import Line from "./Line";
import {useFrame} from "@react-three/fiber";
import {easing} from "maath";

function BriefingFrameCaption({ content }) {
  const { setIsOpenDrawer, setDrawerTitle, setDrawerContent } = useStateContext();
  const [isPointerCursor, setIsPointerCursor] = useState(false);
  const rightLineRef = useRef();
  const leftLineRef = useRef();

  useCursor(isPointerCursor);

  useFrame((state, dt) => {
    rightLineRef.current.visible = isPointerCursor;
    leftLineRef.current.visible = isPointerCursor;

    easing.damp3(rightLineRef.current.position,[isPointerCursor ? 0.09 : 0.05, 0, 0],0.2, dt);
    easing.damp3(leftLineRef.current.position,[isPointerCursor ? -0.1 : -0.05, 0, 0],0.2, dt);
  });

  function calculateOpenButtonPosition() {
    return [0.9, 0.1, 0];
  }

  function openDrawer() {
    setIsOpenDrawer(true);
    setDrawerTitle(content.title);
    setDrawerContent(content.description);
  }

  function calculateRightLineGeometry() {
    const points = [];
    points.push(new THREE.Vector3(0.9, 0.04, 0));
    points.push(new THREE.Vector3(1., 0.04, 0));
    return new THREE.BufferGeometry().setFromPoints(points);
  }

  function calculateLeftLineGeometry() {
    const points = [];
    points.push(new THREE.Vector3(1.095, 0.04, 0));
    points.push(new THREE.Vector3(0.999, 0.04, 0));
    return new THREE.BufferGeometry().setFromPoints(points);
  }

  return (<>
      <Text identifier="open"
            position={calculateOpenButtonPosition()}
            anchorX="left" anchorY="top"
            fontSize={0.034}
            onClick={openDrawer}
            onPointerOver={(e) => (e.stopPropagation(), setIsPointerCursor(true))}
            onPointerOut={() => setIsPointerCursor(false)}
            font={AkzidenzGroteskProRegular}>
        {'περισσότερα'}
      </Text>
    <Line innerRef={rightLineRef} geometry={calculateRightLineGeometry()} color={colors.white} />
    <Line innerRef={leftLineRef} geometry={calculateLeftLineGeometry()} color={colors.white}/>
    </>
  );
}

export default BriefingFrameCaption;