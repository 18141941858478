import {useStateContext} from '../../state/StateContext';
import logo from '../../assets/logo/all_bottom.svg';

function Logo() {
  const { isOpenDrawer } = useStateContext();
  if (isOpenDrawer) return <></>;
  return <img src={logo} style={{position: 'fixed', left: 52.5, bottom: 41, width: 95}} draggable={false}/>;

}

export default Logo;