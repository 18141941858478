export const colors = {
  transparent: '#00000000',
  black: '#000000',
  darkness: '#050505',
  night: '#151515',
  nightWithOpacity75: '#151515CC',
  raisinBlack: '#191920',
  oxfordBlue: '#122030',
  byzantineBlue: '#2F52E0',
  greenBlue: '#0064AF',
  caribbean: '#016563',
  skobeloff: '#017A78',
  darkCyan: '#018D8B',
  cyan: '#02B1AF',
  turquoise: '#02DEDA',
  uranianBlue: '#ACD2ED',
  bronze: '#a37345',
  ecru: '#b2a06e',
  auburn: '#a1302f',
  roseRed: '#bf1151',
  redCrayola: '#EE3351',
  cinnabar: '#E65031',
  bittersweet: '#FF715B',
  sunGlow: '#F9CB40',
  jonquil: '#ffce1f',
  lime: '#BCED09',
  spanishGray: '#969595',
  plainWhite: '#FCFDFE',
  plainWhiteWithOpacity75: '#FCFDFECC',
  lightGrey: '#F1F0EF',
  platinum: '#DEDCDB',
  white: '#FFFFFF',
};