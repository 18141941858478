import {colors} from '../../styles/colors';
import {Button, Divider, Drawer, Typography, Box} from '@mui/material';
import {useStateContext} from '../../state/StateContext';
import Paragraphs from "./Paragraphs";
import {PRIMARY_FONT_SIZE} from "../../config/constants";
import {useState} from "react";

const drawerWidth = '25%';

function SideDrawer() {
  const {isOpenDrawer, setIsOpenDrawer, drawerTitle, drawerContent} = useStateContext();
  const [isHovered, setIsHovered] = useState(false);

  return (
    <Drawer PaperProps={{sx: {backgroundColor: colors.white}}}
            transitionDuration={{enter: 1250, exit: 750}}
            variant="persistent"
            anchor="right"
            open={isOpenDrawer}
            sx={{
              boxShadow: 4,
              overflow: 'hidden',
              width: drawerWidth,
              flexShrink: 0,
              '& .MuiDrawer-paper': {
                width: drawerWidth,
                boxSizing: 'border-box',
              },
            }}>

      <Typography align="left"
                  fontSize={PRIMARY_FONT_SIZE}
                  fontWeight={700}
                  sx={{padding: 2, margin: 1, paddingBottom: 0.5, width: '90%'}}>
        {drawerTitle.toLowerCase()}
      </Typography>

      <Divider sx={{marginX: 3, backgroundColor: colors.black, height: 1.5}}/>

      <Box sx={{padding: 2, margin: 1, paddingTop: 0.5, overflowY: 'auto', overflowX: 'hidden'}}>
        <Paragraphs content={drawerContent} />
      </Box>

      <div style={{display: 'flex', alignItems: 'center', marginTop: `auto`}}>
        <Box textAlign="start" sx={{padding: 2, margin: 1}}>
        <Button onClick={() => setIsOpenDrawer(false)}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                style={{whiteSpace: 'nowrap'}}
                disableRipple
                sx={{
                  px: 0, mx: 0, pb: 0.25,
                  display: 'flex',
                  alignItems: 'center',
                  color: colors.black,
                  backgroundColor: 'transparent',
                  '&.MuiButtonBase-root:hover': {
                    bgcolor: 'transparent',
                  },
                }}>
          <Typography align="left"
                      fontSize={PRIMARY_FONT_SIZE}
                      fontWeight={400}>
            {`κλείσιμο`}&nbsp;&nbsp;
          </Typography>

        </Button>
          <Divider
            style={{
              width: isHovered ? '85%' : '0',
              transition: 'width 0.4s ease',
              transform: 'translate(-50%, 0%)'}}
            sx={{backgroundColor: colors.black, ml: '45%'}}/>
        </Box>
      </div>

    </Drawer>
  );
}

export default SideDrawer;