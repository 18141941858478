import SideDrawer from "../components/ui/SideDrawer";
import GalleryCanvas from "../components/three/GalleryCanvas";
import PreviousAndNextButtons from "../components/ui/PreviousAndNextButtons";
import SourcesButton from "../components/ui/SourcesButton";
import Title from "../components/ui/Title";
import BackButton from "../components/ui/BackButton";
import {useEffect} from "react";
import {useStateContext} from "../state/StateContext";
import {content as hagiography} from "../content/hagiography/content";
import {content as generation} from "../content/generation/content";
import {useLocation} from "wouter";

function GalleryPage() {
  const { exhibition, setExhibition } = useStateContext();
  const [location, ] = useLocation();

  useEffect(() => {
    if (location.startsWith(`/${hagiography.route}`)) setExhibition(hagiography);
    if (location.startsWith(`/${generation.route}`)) setExhibition(generation);
  }, []);

  return (
    <>
      <SideDrawer />
      <GalleryCanvas exhibition={exhibition} />
      <PreviousAndNextButtons images={exhibition.images} />
      <SourcesButton sources={exhibition.sources} />
      <Title title={exhibition.title} />
      <BackButton setExhibition={setExhibition} />
    </>
  )
}

export default GalleryPage;