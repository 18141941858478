import '../../styles/css/loading.css';
import {Box} from '@mui/material';

function Loading({ bottom }) {

  return (
    <Box sx={{position: 'absolute', bottom: bottom, left: '50%', transform: 'translate(-50%, 50%)'}}>
      <span className="loading"></span>
    </Box>
  );
}

export default Loading;