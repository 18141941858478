const DEFAULT_NAMESPACE = 'Client';

const info = (message, object, namespace) => {
  if (object) {
    console.info(
      `[${getDate()}] [INFO] [${namespace || DEFAULT_NAMESPACE}]\n${message}\n`,
      object
    );
  } else {
    console.info(
      `[${getDate()}] [INFO] [${namespace || DEFAULT_NAMESPACE}]\n${message}\n`
    );
  }
};

const warn = (message, object, namespace) => {
  if (object) {
    console.warn(
      `[${getDate()}] [WARN] [${namespace || DEFAULT_NAMESPACE}]\n${message}\n`,
      object
    );
  } else {
    console.warn(
      `[${getDate()}] [WARN] [${namespace || DEFAULT_NAMESPACE}]\n${message}\n`
    );
  }
};

const error = (message, object, namespace) => {
  if (object) {
    console.error(
      `[${getDate()}] [ERROR] [${
        namespace || DEFAULT_NAMESPACE
      }]\n${message}\n`,
      object
    );
  } else {
    console.error(
      `[${getDate()}] [ERROR] [${namespace || DEFAULT_NAMESPACE}]\n${message}\n`
    );
  }
};

const debug = (message, object, namespace) => {
  if (object) {
    console.debug(
      `[${getDate()}] [DEBUG] [${
        namespace || DEFAULT_NAMESPACE
      }]\n${message}\n`,
      object
    );
  } else {
    console.debug(
      `[${getDate()}] [DEBUG] [${namespace || DEFAULT_NAMESPACE}]\n${message}\n`
    );
  }
};

const getDate = () => {
  return new Date().toLocaleTimeString();
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  info,
  warn,
  error,
  debug,
};
