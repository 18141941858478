function Line({ innerRef, geometry, color }) {
  return (
    <group position={[0, 0, 0]} trasparent={true}>
    <line ref={innerRef} geometry={geometry} identifier="line" >
      <lineBasicMaterial attach="material" color={color} trasparent={true} opacity={0} alphaTest={0.5}/>
    </line>
    </group>
  );
}

export default Line;