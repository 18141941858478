import { StateContext } from "./StateContext";
import {useState} from "react";


function StateProvider(props) {
  const [exhibition, setExhibition] = useState({});
  const [cameraSpeed, setCameraSpeed] = useState(1.25);
  const [activeImageIndex, setActiveImageIndex] = useState(null);
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  const [drawerTitle, setDrawerTitle] = useState('');
  const [drawerContent, setDrawerContent] = useState('');

  return (
    <StateContext.Provider
      value={{
        exhibition: exhibition,
        setExhibition: setExhibition,
        cameraSpeed: cameraSpeed,
        setCameraSpeed: setCameraSpeed,
        activeImageIndex: activeImageIndex,
        setActiveImageIndex: setActiveImageIndex,
        isOpenDrawer: isOpenDrawer,
        setIsOpenDrawer: setIsOpenDrawer,
        drawerTitle: drawerTitle,
        setDrawerTitle: setDrawerTitle,
        drawerContent: drawerContent,
        setDrawerContent: setDrawerContent,
    }}>
      {props.children}
    </StateContext.Provider>
  );
}

export default StateProvider;