import * as THREE from "three";
import {useRef, useState} from 'react';
import getUuid from 'uuid-by-string';
import {Text, useCursor} from '@react-three/drei';
import {useFrame} from '@react-three/fiber';
import {easing} from 'maath';
import AkzidenzGroteskProRegular from '../../assets/fonts/AkzidenzGroteskPro-Regular.ttf';
import AkzidenzGroteskProBold from '../../assets/fonts/AkzidenzGroteskPro-Bold.ttf';
import {colors} from "../../styles/colors";
import BriefingFrameCaption from './BriefingFrameCaption';
import {useStateContext} from '../../state/StateContext';
import {useRoute} from 'wouter';
import Line from "./Line";

function BriefingFrame({ ...image }) {
  const { exhibition, isOpenDrawer } = useStateContext();
  const frameRef = useRef();
  const [, route] = useRoute(`/${exhibition.route}/:id`);
  const [isPointerCursor, setIsPointerCursor] = useState(false);
  const name = getUuid(image.url);

  const isActive = route?.id === name;
  const aspectRatio = image.width / image.height;

  useCursor(isPointerCursor);

  useFrame((state, dt) => {
    renderFrame(dt);
  });

  function renderFrame(dt) {
    // The following line adds highlight color on hover
    easing.dampC(
      frameRef.current.material.color,
      isPointerCursor ? colors.ecru : colors.white,
      0.1, dt
    )
  }

  function calculateFrameScale() {
    const meshScale = 0.8;
    return [aspectRatio * 2 * meshScale, 2 * meshScale, 0.05]
  }

  function calculateLineGeometry() {
    const points = [];
    points.push(new THREE.Vector3(-0.6375, 1.3275, 0.035))
    points.push(new THREE.Vector3(0.6375, 1.3275, 0.035))
    return new THREE.BufferGeometry().setFromPoints(points);
  }


  return (
    <group position={image.position} rotation={image.rotation}>
      <mesh
        name={name}
        onPointerOver={(e) => (e.stopPropagation(), setIsPointerCursor(true))}
        onPointerOut={() => setIsPointerCursor(false)}
        scale={calculateFrameScale()}
        position={[0, 0.8, 0]}
      >
        <boxGeometry />
        <meshStandardMaterial color={colors.night} metalness={0.5} roughness={0.5} envMapIntensity={2} />
        <mesh ref={frameRef} raycast={() => null} scale={[0.9, 0.93, 0.9]} position={[0, 0, 0.2]}>
          <boxGeometry />
          <meshBasicMaterial toneMapped={false} fog={false} />
        </mesh>
        <Text maxWidth={0.8} anchorX="left" anchorY="top" position={[-0.4, 0.4, 0.75]} fontSize={0.035}
              color={colors.black} font={AkzidenzGroteskProBold} identifier="text" scale={[1, 1, 1]}>
          {exhibition.title.toLowerCase()}
        </Text>

        <Text lineHeight={1.5} maxWidth={0.8} anchorX="left" anchorY="top" position={[-0.4, 0.3, 0.75]} fontSize={0.025}
              color={colors.black} font={AkzidenzGroteskProRegular} identifier="text" scale={[1, 1, 1]}>
          {exhibition.briefing}
        </Text>
      </mesh>
      <Line geometry={calculateLineGeometry()} color={colors.black}/>
      {(isActive && !isOpenDrawer) && <BriefingFrameCaption content={exhibition} />}
    </group>
  )
}

export default BriefingFrame;