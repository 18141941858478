import {Link, Typography} from '@mui/material';
import {SECONDARY_FONT_SIZE} from "../../config/constants";
import {isValidURL} from '../../utils/isValidURL';

function Paragraphs({ content }) {
  return (<>
    {content.split('\n').map((paragraph, index, paragraphs) => {
      if (isValidURL(paragraph)) return (
        <Link key={index} href={paragraph} rel="noopener noreferrer" color="inherit" target="_blank" underline="hover">
          <Typography  align="left" fontSize={SECONDARY_FONT_SIZE} fontWeight={400}
                       sx={{marginBottom: index === paragraphs.length-1 ? 0 : 1,
                         display: '-webkit-box',
                         overflow: 'hidden',
                         WebkitBoxOrient: 'vertical',
                         WebkitLineClamp: 2,
                       }}>
          {paragraph}
          </Typography>
        </Link>
      );
      return (
        <Typography key={index} align="left"
                    fontSize={SECONDARY_FONT_SIZE}
                    fontWeight={400}
                    sx={{paddingBottom: index === paragraphs.length-1 ? 0 : 1}}>
          {paragraph}
        </Typography>
      );
    })}
  </>)
}

export default Paragraphs;