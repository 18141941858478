import { createContext, useContext } from "react";

export const StateContext = createContext({
  exhibition: {},
  cameraSpeed: null,
  setCameraSpeed: () => {},
  activeImageIndex: null,
  setActiveImageIndex: () => {},
  isOpenDrawer: false,
  setIsOpenDrawer: () => {},
  drawerTitle: '',
  drawerContent: '',
});

export const useStateContext = () => useContext(StateContext);