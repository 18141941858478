import logger from './logger';
import axios from 'axios';

export const SERVER = `http://localhost:6337`;
export const API = `${SERVER}/api`;
export const API_CALL_DELAY = 2000;
export const EXHIBITIONS = '/exhibitions';
export const POPULATE = '?populate[1]=images'

export async function getExhibition(id, exhibition) {
  const queryString = API + EXHIBITIONS + `/${id}` + POPULATE;
  await axios
    .get(queryString)
    .then(({ data }) => {
      parseData(data.data, exhibition);
      logger.info('Fetched exhibition: ', data.data);
    })
    .catch((error) => logger.error('Error: ', error));
}

function parseData(data, exhibition) {
  try {
    exhibition.title = parseString(data.attributes.title);
    exhibition.description = parseString(data.attributes.description);
    exhibition.briefing = parseString(data.attributes.briefing);
    exhibition.sources = parseString(data.attributes.sources);
    for (const fetchedImage of data.attributes.images.data) {
      const identifier = fetchedImage.attributes.identifier;
      const exhibitionImage = findExhibitionImageByIdentifier(identifier, exhibition);
      exhibitionImage.title = parseString(fetchedImage.attributes.title);
      exhibitionImage.description = parseString(fetchedImage.attributes.description);
    }
  }
  catch { logger.error('Error in parseData: ', data); }
}

function parseString(string) {
  if (string === null || string === undefined) return '';
  return string;
}

function findExhibitionImageByIdentifier(identifier, exhibition) {
  return exhibition.images.find((image) => image.identifier === identifier);
}