import {Box, Divider, Typography} from "@mui/material";
import {colors} from "../../styles/colors";
import {useStateContext} from "../../state/StateContext";
import {TITLE_FONT_SIZE} from "../../config/constants";

function Title({ title }) {
  const { activeImageIndex, isOpenDrawer } = useStateContext();

  if (title === null || title === undefined) return;
  if (activeImageIndex !== null || isOpenDrawer) return;

  return (
    <Box textAlign="end" sx={{position: "absolute", top: 34, right: 35,
    }}>
      <Typography sx={{fontSize: TITLE_FONT_SIZE, color: colors.white, py: 1 }}>
        {title.toLowerCase()}
      </Typography>
      <Divider sx={{backgroundColor: colors.white, height: 1.5}} />
    </Box>
  );
}

export default Title;