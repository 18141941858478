import {Typography} from '@mui/material';
import {colors} from '../../styles/colors';
import {TITLE_FONT_SIZE} from '../../config/constants';

function WindowSizeWarning() {
  return (
    <Typography sx={{
      textAlign: 'center',
      top: '50%',
      position: 'absolute',
      color: colors.white,
      fontSize: TITLE_FONT_SIZE,
      width: '100%',
    }}>
      παρακαλώ μεγεθύνετε το παράθυρο
    </Typography>
  );
}

export default WindowSizeWarning;