import {content as hagiography} from "../content/hagiography/content";
import {content as generation} from "../content/generation/content";
import {useLocation} from "wouter";
import {Box, Button, Divider, Fade} from '@mui/material';
import {colors} from "../styles/colors";
import {useState} from "react";
import { ReactComponent as Papaloukas } from '../assets/title/papaloukas_title_versions_white.svg';
import {TITLE_FONT_SIZE} from "../config/constants";
import {useStateContext} from "../state/StateContext";
import GalleryPage from "./GalleryPage";
import Loading from '../components/ui/Loading';
import {API_CALL_DELAY} from '../config/api';

function WelcomePage({ isLoading }) {
  const { exhibition, setExhibition } = useStateContext();
  const [location, setLocation] = useLocation();
  const [isHagiographyHovered, setIsHagiographyHovered] = useState(false);
  const [isGenerationHovered, setIsGenerationHovered] = useState(false);

  function isHagiographySelected() {
    return location.startsWith(`/${hagiography.route}`);
  }

  function isGenerationSelected() {
    return location.startsWith(`/${generation.route}`);
  }

  function isOnWelcomePage() {
    return (!isHagiographySelected() && !isGenerationSelected()) || exhibition === {};
  }

  function selectHagiographyExhibition() {
    setLocation(`/${hagiography.route}`);
    setExhibition(hagiography);
    setIsHagiographyHovered(false);
  }

  function selectGenerationExhibition() {
    setLocation(`/${generation.route}`);
    setExhibition(generation);
    setIsGenerationHovered(false);
  }

  if (isOnWelcomePage()) {
    return (
      <Box sx={{width: '100%', height: '100%', backgroundColor: colors.darkness}}>
        <Papaloukas style={{position: 'absolute', top: '10%', height: '42.5%'}} />
        {isLoading ?
          <Loading bottom={'15%'}/>
          :
          <Fade in={!isLoading} timeout={API_CALL_DELAY}>
            <div>

              <Box textAlign="start" sx={{position: "absolute", bottom: 95, right: '55%'}}>
                <Button id="previous" onClick={selectHagiographyExhibition} disableRipple
                        onMouseEnter={() => setIsHagiographyHovered(true)}
                        onMouseLeave={() => setIsHagiographyHovered(false)}
                        sx={{
                          px: 0.25, m: 0, pb: 0.25,
                          fontSize: TITLE_FONT_SIZE,
                          color: colors.white,
                          backgroundColor: 'transparent',
                          '&.MuiButtonBase-root:hover': {
                            bgcolor: 'transparent',
                          },
                        }}>
                  {hagiography.title.toLowerCase()}
                </Button>
                <Divider
                  style={{
                    width: isHagiographyHovered ? '98%' : '0',
                    transition: 'width 0.4s ease',
                    transform: 'translate(-50%, 0%)'}}
                  sx={{backgroundColor: colors.white, ml: '50%'}}/>
              </Box>

              <Box textAlign="start" sx={{position: "absolute", bottom: 95, left: '55%'}}>
                <Button id="previous" onClick={selectGenerationExhibition} disableRipple
                        onMouseEnter={() => setIsGenerationHovered(true)}
                        onMouseLeave={() => setIsGenerationHovered(false)}
                        sx={{
                          px: 0.25, m: 0, pb: 0.25,
                          fontSize: TITLE_FONT_SIZE,
                          color: colors.white,
                          backgroundColor: 'transparent',
                          '&.MuiButtonBase-root:hover': {
                            bgcolor: 'transparent',
                          },
                        }}>
                  {generation.title.toLowerCase()}
                </Button>
                <Divider
                  style={{
                    width: isGenerationHovered ? '98%' : '0',
                    transition: 'width 0.4s ease',
                    transform: 'translate(-50%, 0%)'}}
                  sx={{backgroundColor: colors.white, ml: '50%'}}/>
              </Box>

            </div>
          </Fade>
        }

          </Box>
    );
  }

  return <GalleryPage />;
}

export default WelcomePage;