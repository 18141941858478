import {useLocation} from "wouter";
import {colors} from "../../styles/colors";
import {Box, Button, Divider} from '@mui/material';
import {useStateContext} from '../../state/StateContext';
import {useState} from "react";
import {PRIMARY_FONT_SIZE} from "../../config/constants";

function BackButton() {
  const { isOpenDrawer, setActiveImageIndex } = useStateContext();
  const [, setLocation] = useLocation();
  const [isHovered, setIsHovered] = useState(false);

  if (isOpenDrawer) return <></>;

  function leaveExhibition() {
    setLocation('/');
    setActiveImageIndex(null);
  }

  return (
    <Box textAlign="start" sx={{position: "absolute", top: 35, left: 35}}>
      <Button id="previous" onClick={leaveExhibition} disableRipple
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
              sx={{
                px: 0.25, m: 0, pb: 0.25,
                fontSize: PRIMARY_FONT_SIZE,
                color: colors.white,
                backgroundColor: 'transparent',
                '&.MuiButtonBase-root:hover': {
                  bgcolor: 'transparent',
                },
              }}>
          {'πίσω'}
      </Button>
      <Divider
        style={{
          width: isHovered ? '50%' : '0',
          transition: 'width 0.4s ease',
          transform: 'translate(-50%, 0%)'}}
        sx={{backgroundColor: colors.white, ml: '50%'}}/>
    </Box>
  );
}

export default BackButton;