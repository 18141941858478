import { createTheme } from '@mui/material';
import {colors} from './colors';
import AkzidenzGroteskProRegular from '../assets/fonts/AkzidenzGroteskPro-Regular.ttf';


const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: colors.cyan,
      contrastText: colors.white, // Set button text to white instead of black
    },
    secondary: {
      main: colors.spanishGray,
    },
    background: {
      default: colors.lightGrey,
      paper: colors.lightGrey,
    },
    warning: {
      main: colors.auburn,
    },
    error: {
      main: colors.auburn,
    },
    black: {
      main: colors.black,
    },
    info: {
      main: colors.darkCyan,
    }
  },
  typography: {
    fontFamily: "Akzidenz-Grotesk",
    fontSize: 14,
    button: {
      textTransform: 'none',
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        "@font-face": {
          fontFamily: 'Akzidenz-Grotesk',
          // fontWeight: 'bold',
          fontStyle: 'normal',
          src: `url(${AkzidenzGroteskProRegular}) format("truetype")`
          /* Set fontWeight value in Typography component (100-1000) */
        }
      },
    },
  }
});

export default theme;
