import './App.css';
import {CssBaseline, ThemeProvider} from '@mui/material';
import theme from './styles/theme';
import WelcomePage from "./pages/WelcomePage";
import Logo from './components/ui/Logo';
import {useWindowSize} from './utils/useWindowSize';
import WindowSizeWarning from './components/ui/WindowSizeWarning';
import {useEffect, useState} from 'react';
import {API_CALL_DELAY, getExhibition} from './config/api';
import {content as hagiography} from "./content/hagiography/content";
import {content as generation} from "./content/generation/content";

function App() {
  const [width, height] = useWindowSize();
  const [isLoading, setIsLoading] = useState(true);
  const MIN_WIDTH = 900;
  const MIN_HEIGHT = 500;

  useEffect(() => {
    const runQueries = async () => {
      await getExhibition(1, hagiography);
      await getExhibition(2, generation);
    };

    runQueries().then(async () => {
      setTimeout(() => setIsLoading(false), API_CALL_DELAY);
    })
  }, []);

  function isWindowSizeBelowMin() {
    return width < MIN_WIDTH || height < MIN_HEIGHT;
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {isWindowSizeBelowMin() ?
        <WindowSizeWarning />
        :
        <>
          <WelcomePage isLoading={isLoading} />
          <Logo />
        </>
      }
    </ThemeProvider>
  );
}

export default App;