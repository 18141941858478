import Frames from './Frames';
import {Environment, MeshReflectorMaterial} from '@react-three/drei';
import {Canvas} from '@react-three/fiber';
import {colors} from "../../styles/colors";
import {useEffect, useState} from 'react';
import {useStateContext} from '../../state/StateContext';
import Loading from '../ui/Loading';
import '../../styles/css/scene.css';
import {API_CALL_DELAY} from '../../config/api';

function GalleryCanvas() {
  const { setCameraSpeed } = useStateContext();
  const [isLoading, setIsLoading] = useState(true);
  const framesPosition = [0, -0.5, -12];

  useEffect(() => {
    setCameraSpeed(1.25);
    const timeout = setTimeout(() => setIsLoading(false), API_CALL_DELAY);
    return () => clearTimeout(timeout);
  }, []);

  function handleOnCreated() {
    setIsLoading(false);
  }

  return (
    <>
    {isLoading && <Loading bottom={'50%'}/>}
    <div className={`scene-container ${!isLoading ? 'loaded' : ''}`}>
      <Canvas dpr={[1, 1.5]} camera={{ fov: 70, position: [0, 6, 30] }} onCreated={handleOnCreated}>
        <color attach="background" args={[colors.darkness]} />
        <fog attach="fog" args={[colors.darkness, 0, 15]} />
        <group position={framesPosition}>
          <Frames />
          <mesh rotation={[-Math.PI / 2, 0, 0]}>
            <planeGeometry args={[50, 50]} />
            <MeshReflectorMaterial
              blur={[600, 100]}
              resolution={1024}
              mixBlur={1}
              mixStrength={80}
              roughness={1}
              depthScale={1.2}
              minDepthThreshold={0.4}
              maxDepthThreshold={1.4}
              color={colors.darkness}
              metalness={0.5}
            />
          </mesh>
        </group>
        <Environment preset="city" />
      </Canvas>
      </div>
    </>
  )
}

export default GalleryCanvas;