export const content = {
  route: 'agiographia',
  title: 'Ο Παπαλουκάς και η Αγιογραφία',
  briefing: 'Ο Σπύρος Παπαλούκας μαθήτευσε από μικρός δίπλα στον αγιογράφο Νικόλαο Παπακωνσταντίνου. Kατεξοχήν τοπιογράφος, αποστέλλεται ως πολεμικός ζωγράφος στη μικρασιατική εκστρατεία, και αργότερα, ως αυτόπτης μάρτυρας της μικρασιατικής καταστροφής, το 1923 φεύγει για το Άγιο Όρος. Η εκεί του παραμονή λειτουργεί ως κάθαρση από την τραγωδία, σφυρηλατεί τη δική του θεωρία για μια υγιή σχέση με την παράδοση και επιπλέον, διαμορφώνει τη σχέση του με τη Βυζαντική και Νεοβυζαντινή τέχνη.\n' +
    '\n' +
    'Η έκθεση με τα έργα του Σπύρου Παπαλουκά που ακολουθεί, επιχειρεί να σκιαγραφήσει την πορεία του καλλιτέχνη σε σχέση με τις Αγιογραφίες και τη Βυζαντινή Τέχνη. Πιο συγκεκριμένα, γίνεται αναφορά στην παραμονή του καλλιτέχνη στο Άγιο Όρος, στο βαθμό που επηρεάστηκε από αυτή, στον πλούτο των σχετικών έργων του, καθώς και στη μετέπειτα πορεία του στο πλαίσιο της ελληνικής εκκλησιαστικής και Βυζαντινής ζωγραφικής.\n',
  description: 'Ο ζωγράφος Σπύρος Παπαλουκάς (1892-1957) ανήκει σ’ εκείνη την γενιά των Ελλήνων καλλιτεχνών που αφομοίωσαν τα διδάγματα και τις μορφολογικές αξίες της αυθεντικής ζωγραφικής της παράδοσής μας.\n' +
    '\n' +
    'Στην προσπάθεια αυτής της αναζήτησης είχε δύο πολύτιμες εμπειρίες στις πνευματικο-καλλιτεχνικές αποσκευές του: α. την θητεία του στο Παρίσι (1917-1921), όπου ήλθε σε επαφή με τα έργα των υπαιθριστών, των Nabis, και των φωβιστών, κι ακόμη, με τον κυβισμό και β. το προσκύνημα-άσκηση στο Άγιο Όρος (1923-1924), όπου παραμένει για έναν ολόκληρο χρόνο, μελετώντας την φύση και τα αρχιτεκτονήματα, και συνομιλώντας με την βυζαντινή ζωγραφική.\n' +
    '\n' +
    'Η διπλή αυτή εμπειρία τον βοήθησε καθοριστικά στην ανακάλυψη κοινών στοιχείων, μορφολογικών κυρίως και βαθύτερα αισθητικών, που ανέπαυαν τις εσωτερικές του αναζητήσεις και δονήσεις. Τα κοινά αυτά στοιχεία θα μπορούσαν να προσδιοριστούν στην δισδιάστατη αντίληψη της ζωγραφικής επιφάνειας που χαρακτηρίζει την βυζαντινή ζωγραφική, αλλά και πολλά από τα κινήματα της μοντέρνας τέχνης, την γεωμετρική οργάνωση της σύνθεσης και την εσωτερική ισορροπία, την κυριαρχία του χρώματος, του δυνατού, καθαρού χρώματος, χωρίς διαβαθμίσεις, στοιχεία που υπάρχουν στο φωβισμό και τις μετα-εμπρεσσιονιστικές επιλογές. \n' +
    '\n' +
    'Κατά την παραμονή του στο Άγιο Όρος, ο Παπαλουκάς αντιγράφει τοιχογραφίες και εικόνες διαφόρων μονών, Βυζαντινών και Μεταβυζαντινών (Μ. Λαύρας, Σιμωνόπετρας, Διονυσίου, Ιβήρων), με την ταπείνωση ενός σπουδαστού προσπαθώντας με την μελέτη αυτή να εισχωρήσει στο βαθύτερο επίπεδο λειτουργίας της εικόνας. Όταν ζωγραφίζει τα αρχιτεκτονήματα, μοναστήρια, αρσανάδες, φιάλες, κ.ά., τα συνδέει με τις κατακτήσεις του υπαιθρισμού και με τις συνθετικές αρχές της αρχιτεκτονικής. \n' +
    '\n' +
    'Στο Άγιο Όρος, ο Παπαλουκάς μελετάει το αγιορείτικο τοπίο και τη βυζαντινή τέχνη, δουλεύοντας ακατάπαυστα και δημιουργώντας την “περίοδο του Αγίου Όρους”.\n' +
    '\n' +
    'Επίσης, εκεί παρατηρεί και “σπουδάζει” κοντά στα βυζαντινά και μεταβυζαντινά αριστουργήματα και τις φορητές εικόνες (εικόνες 1-3). \n' +
    '\n' +
    'Στο θρησκευτικό έργο του «αρσανάδες» δεν θα διστάσει να χρησιμοποιήσει και κάποιες απηχήσεις του κυβισμού, συνδυασμένες με την ένταση του χρώματος του φωβισμού και με εξαιρετικά αισθητικά αποτελέσματα (εικόνες 4-5).\n' +
    '\n' +
    'Με την επιστροφή του από το Άγιο Όρος, και μετά από πανελλήνιο διαγωνισμό, παίρνει το βραβείο και την ανάθεση αγιογράφησης της Μητρόπολης Άμφισσας.\n' +
    'Με αυτόν τον οπλισμό, ο Παπαλουκάς θα αναλάβει το εγχείρημα για πρώτη φορά να ξαναφέρει στη νεότερη Ελλάδα την Βυζαντινή ζωγραφική σε μια σύγχρονη εκκλησία στην Μητρόπολη της Άμφισσας. Είχε προηγηθεί ο Κωνσταντίνος Παρθένης στα 1919 με τους μεγάλους πίνακες-εικόνες στον Άγιο Αλέξανδρο Παλαιού Φαλήρου, όπου όμως ο μεγάλος ζωγράφος είχε χρησιμοποιήσει μόνο κάποια στοιχεία από την Βυζαντινή ζωγραφική. Τώρα, ο Παπαλουκάς, στα 1927, αναλαμβάνει να ιστορήσει μια μεγάλη εκκλησία με μια τεχνοτροπία που βασίζεται στην Βυζαντινή παράδοση με τον καθοριστικό τρόπο της δισδιάστατης ζωγραφικής, αλλά και την προσωπική του γραφή, διαμορφωμένη και από την παρισινή του θητεία (εικόνες 6-9).\n' +
    '\n' +
    'Τέλος, κατά την περίοδο 1937-39, το Υπουργείο Παιδείας του αναθέτει την εικονογράφηση  του ναού της Σχολής Νομικού.\n' +
    '\n' +
    'Το έργο αυτό σημειώνει σταθμό στην ελληνική εκκλησιαστική ζωγραφική, καθώς αποτελεί την αφετηρία για την επιστροφή στην τεχνοτροπία που επί αιώνες είχε εκφράσει με εικαστικά μέσα την αλήθεια της Ορθόδοξης Εκκλησίας. Συνακόλουθα, ο Παπαλουκάς άνοιξε το δρόμο προς την ζωγραφική της παράδοσης, θεμελιώνοντάς τον στο ισχυρό και γόνιμο ζωγραφικό του ταλέντο, που τρεφότανε από την συνεχή σπουδή και κατανόηση της Βυζαντινής ζωγραφικής (εικόνες 10-11).\n',
  sources: 'Νίκος Ζίας, Ομ. Καθηγητής της Ιστορίας της Τέχνης, Πανεπιστημίου Αθηνών.\n' +
    '\n' +
    'Χρυσάνθη Μπαλτογιάννη, Διευθύντρια Βυζαντινού Μουσείου Αθηνών (1996-1999).\n' +
    '\n' +
    'Κατάλογος «Σπύρος Παπαλουκάς. Αγιογραφίες, Σχέδια, Μακέτες. Συλλογή Ιδρύματος Εικαστικών Τεχνών και Μουσικής, Βασίλη και Μαρίνας Θεοχαράκη», 2009.\n',
  images: [
    {
      identifier: 0,
      num: 'Εικόνα 0',
      title: 'Ο Παπαλουκάς και η Αγιογραφία',
      description: '-',
      position: [0, 0, 10],
      rotation: [0, 0, 0],
      url: '0',
      width: 1322,
      height: 1322,
    },
    {
      identifier: 8,
      num: 'Εικόνα 8',
      title: 'Τμήμα από τη Μακέτα για την εικονογράφηση της Παλαιάς Επισκοπής Τεγέας, τέμπερα, 50 x 140 εκ.',
      description: 'Το εξαιρετικό αυτό έργο που μεταφέρει στοιχεία από την μακέτα της Μητροπόλεως της Άμφισσας, παραμένει παρόλα αυτά, μία επιτυχημένη, ευγενική και συνεπής απόδοση των παραστάσεων του Χριστού στον τρούλο, στην Παναγία Πλατυτέρα και στη σειρά των Προφητών σε παράταξη και σε δίζωνη παράσταση. Είναι τώρα εμφανής η προσπάθεια του ζωγράφου για να κοσμήσει τις μορφές με περισσότερη καλλιέπεια και χάρη.',
      position: [-10, 0, 6],
      // position: [-5, 2.5, 3],
      rotation: [0, Math.PI / 4, 0],
      url: '/hagiography/images/8.jpg',
      width: 2558,
      height: 937,
    },
    {
      identifier: 2,
      num: 'Εικόνα 2',
      title: 'Ο Χριστός, μολύβι,  κραγιόνια και υδρόχρωμα σε διαφανές χαρτί, 109 x 53.5 εκ.',
      description: 'Έχει ήδη επισημανθεί ότι, στο Χριστοκεντρικό χαρακτήρα της Ορθοδοξίας η βυζαντινή και μεταβυζαντινή εικονογραφία, δύσκολα και σπάνια επιχειρεί να παραλλάσσει τη μορφή του Χριστού. Μικρές αποκλίσεις που παρατηρούνται εκεί, είναι οι κάποιες προσπάθειες που αποσκοπούν να αποδώσουν τη διττή ανθρώπινη και θεϊκή φύση του σε μια μορφή.\n' +
        'Αναγνωρίσαμε βέβαια βυζαντινές παραστάσεις που διακριτικά τονίζουν τόσο, τα ανθρώπινα, όσο και τα θεία χαρακτηριστικά του. Αυτά βέβαια αποτελούν δημιουργίες των μεγάλων ζωγράφων του Βυζαντίου.\n' +
        'Το πρόβλημα αντιμετώπισε πιθανότατα και ο ζωγράφος μας που επιχειρεί σε αρκετά παρόμοια σχέδια να αποδώσει τον Χριστό Παντοκράτορα όρθιο και μετωπικό, κρατώντας στο αριστερό χέρι, το ανοικτό ευαγγέλιο.\n',
      position: [-8, 0, 4],
      rotation: [0, Math.PI / 5, 0],
      url: '/hagiography/images/2.jpg',
      width: 696,
      height: 1439,
    },
    {
      identifier: 3,
      num: 'Εικόνα 3',
      title: 'Ο Άγιος Δημήτριος, λάδι σε μουσαμά, 122 x 122 εκ.',
      description: 'O άγιος Δημήτριος εικονίζεται έφιππος, σε μεγάλα σχέδια και σε λάδι. Η σύνθετη και απαιτητική παράσταση που δεν συναντάται συχνά στη βυζαντινή εικονογραφία βρίσκει προηγούμενο στις ιπποτικές παραστάσεις του μεσαίωνα. Ο άγιος εικονίζεται έφιππος με πλούσια περιβολή, κρατώντας τώρα στον ώμο το κοντάρι του στην άκρη του οποίου, ανεμίζει το λάβαρο της νίκης του. Η στιγμή είναι θριαμβική και παραπέμπει στην επιβράβευση του αγίου από τον Χριστό, όπως δηλώνεται από το χέρι που προβάλλει από ουράνιο τεταρτοσφαίριο και ευλογεί τον άγιο.',
      position: [-6, 0, 2],
      rotation: [0, Math.PI / 5, 0],
      url: '/hagiography/images/3.jpg',
      width: 1413,
      height: 1439,
    },
    {
      identifier: 4,
      num: 'Εικόνα 4',
      title: 'Αρσανάς Μονής Παντοκράτορος, μολύβι και κάρβουνο σε χαρτί kraft, 59 x 50 εκ.',
      description: 'Με φόντο το φυσικό τοπίο και σε παραθαλάσσια θέση με κολπίσκους, προβλήτες, σκάλες, προστατευτικά κτιστά μέτωπα, βάρκες, ξύλινα καγκελώματα και δύο γραφικά αρσανόσπιτα με οξυκόρυφες δίρριχτες στέγες, απ’ όπου προβάλλουν οι ψηλές καμινάδες, αποδίδεται εδώ η ατμόσφαιρα μιας ακόμη καθημερινής περιπέτειας της ζωής του μοναχισμού.',
      position: [-4, 0, 0],
      rotation: [0, Math.PI / 5, 0],
      url: '/hagiography/images/4.jpg',
      width: 1242,
      height: 1439,
    },
    {
      identifier: 5,
      num: 'Εικόνα 5',
      title: 'Αρσανάς Μονής Παντοκράτορος, 1935, λάδι σε χαρτόνι, 81 x 75 εκ',
      description: 'Το σχέδιο μεταφέρθηκε σε λάδι με λαμπρά πορτοκαλοκόκκινα και άλλα αστραφτερά χρώματα του Παπαλουκά',
      position: [-2, 0, -2],
      rotation: [0, 0, 0],
      url: '/hagiography/images/5.jpg',
      width: 1355,
      height: 1439,
    },
    {
      identifier: 6,
      num: 'Εικόνα 6',
      title: 'Μακέτα Μητροπόλεως Άμφισσας, 1927, τέμπερα σε χαρτί, 96,5 x 196 εκ.',
      description: 'Ο Παντοκράτωρ στο μετάλλιο του τρούλλου στη μακέτα της κόσμησης του ναού της Μητροπόλεως στην Άμφισσα.\n' +
        'Η δυναμική και ρωμαλέα μορφή του Χριστού Παντοκράτορα ευλογεί και κρατεί στο αριστερό χέρι και μπροστά στο στήθος, κλειστό ευαγγέλιο με το πάχος των σελίδων στην εσωτερική πλευρά. Στην ευλογία μένει στο σχήμα με την παλάμη προς τα έξω και τον αντίχειρα ενωμένο με τον παράμεσο και το δείκτη. Το σχήμα κωδικοποιήθηκε\n' +
        'ως στοιχείο που χαρακτηρίζει τη μορφή του Χριστού Παντοκράτορα.\n' +
        'Η μορφή περιβάλλεται από κυκλική ζώνη κοσμημένη με τέσσερα σεραφείμ σε σταυρική διάταξη γύρω από την προτομή του Χριστού, και με τέσσερα μικρά μετάλλια στα διάκενα που περικλείουν τις προτομές της Παναγίας, του Ιωάννη του Προδρόμου και των αρχαγγέλων Γαβριήλ και Μιχαήλ.\n' +
        'Στα λοφία εικονίζονται, ο Ιωάννης ο Θεολόγος με το σύμβολο του αετού και ο Λουκάς με τον «βουν», ο Ματθαίος με τον «άγγελο» και ο Μάρκος με τον «λέοντα».\n' +
        'Το μετάλλιο εντάσσεται σε υπόλευκο ρομβοειδές μέτωπο, που παραπέμπει σε «δόξα» στις πλευρές του οποίου ακουμπούν τα τέσσερα λοφία. Το όλο θέμα προβάλλεται σε υπόλευκο επίσης, τετράγωνο πεδίο.\n' +
        'Στις άντυγες των τόξων γράφονται μεταξύ τους μετωπικοί και ολόσωμοι οι άγιοι Θεόδωροι, Τήρων και Στρατηλάτης, οι άγιοι Δημήτριος και Γεώργιος και οι αρχάγγελοι Μιχαήλ και Γαβριήλ.\n' +
        'Στην παράσταση του τρούλλου αναγνωρίζεται εύκολα, το προσωπικό ύφος του ζωγράφου μας, τόσο στην πρωτότυπη και σύνθετη διευθέτηση της σύνθεσης, όσο και στο ήθος των μορφών. Ιδιαίτερα για τη μορφή του Χριστού μπορεί να θεωρηθεί ότι, ο ζωγράφος προβληματίζεται σοβαρά, όπως συνέβαινε και στους βυζαντινούς ζωγράφους, για να αποδώσει τη διττή φύση του Χριστού. Παρατηρείται και εδώ η θλίψη της ανθρώπινης περιπέτειας, συνδυασμένη με την ουράνια παρουσία του σε δόξα.\n',
      position: [0, 0, -2],
      rotation: [0, 0, 0],
      url: '/hagiography/images/6.jpg',
      width: 674,
      height: 1352,
    },
    {
      identifier: 7,
      num: 'Εικόνα 7',
      title: 'Μακέτα Μητροπόλεως Άμφισσας, τομή εγκάρσια του Ιερού Βήματος (εσωτερικό), 1927, σινική μελάνη, 53,5 x 62 εκ.',
      description: 'Πρόκειται για αξιόλογες παραστάσεις σε αρχιτεκτονικά σχέδια του Μητροπολιτικού Ναού της Άμφισσας. Στα σχέδια του Ιδρύματος περιλαμβάνονται και εγκάρσιες τομές, στις οποίες ο ζωγράφος μας εντάσσει σχέδια παραστάσεων, όπως το λεπτομερειακό σχέδιο εγκάρσιας τομής του Ιερού που γράφεται εκεί, το όλο εικονογραφικό του πρόγραμμα. Στο εσωτερικό του Βήματος εικονίζεται η δίζωνη σε διάταξη Θεία Λειτουργία, που στέφεται από την Πλατυτέρα, η Άκρα Ταπείνωση στην κόγχη της Πρόθεσης, κάτω από τον Παλαιό των Ημερών, και η Πλατυτέρα σε αστερώδη κάμπο, κάτω από την παράσταση με τους Τρεις παίδες εν Καμίνω, στην κόγχη του Διακονικού.\n' +
        '\n' +
        'Τα ευαίσθητα αυτά σχέδια πιστά τώρα, στη βυζαντινή παράδοση, φαίνεται να αποτελούν εξαίρετα δείγματα μιας πολύπλευρης έφεσης του καλλιτέχνη Σπύρου Παπαλουκά.\n',
      position: [2, 0, -2],
      rotation: [0, 0, 0],
      url: '/hagiography/images/7.jpg',
      width: 1679,
      height: 1431,
    },
    {
      identifier: 1,
      num: 'Εικόνα 1',
      title: 'Ο άγιος Δημήτριος, όρθιος και μετωπικός, μολύβι, υδατοχρώματα και κραγιόνια σε χαρτί kraft, 158 x 105 εκ.',
      description: 'Το χρωματιστό σχέδιο του Παπαλουκά παριστάνει τον άγιο Δημήτριο με απλή τη στρατιωτική του περιβολή και με στρογγυλό πρόσωπο που αποπνέει ειρηνική διάθεση, νεανική αθωότητα, και αγιοσύνη. Λείπουν εδώ, το τόξο, η φαρέτρα, το κράνος, το σπαθί και η ασπίδα, έτοιμη κρεμασμένη στον ώμο των στρατιωτικών αγίων, όπως συμβαίνει στις παραστάσεις τους τον 13ο αιώνα. Αντίθετα ο Παπαλουκάς φαίνεται να γνώρισε στο ΄Ορος, τη μεγάλη σειρά στρατιωτικών αγίων στη Μονή Διονυσίου που ντυμένοι με βαρύτιμα χρυσοκέντητα ρούχα, όρθιοι και μετωπικοί λάμπουν από ευγένεια, κρατώντας στο χέρι μόνο το σταυρό. Γοητευμένος πιθανότατα, από αυτά τα σύνθετα σχήματα στις πριγκιπικές αυτές μορφές απομακρύνθηκε από αυτόν τον ουράνιο χώρο και κρατώντας μόνον την ιδέα της ειρηνικής παρουσίας τους, έγραψε τη δική του παράσταση με μόνο το χρώμα και τη φόρμα.',
      position: [4, 0, 0],
      rotation: [0, -Math.PI / 5, 0],
      url: '/hagiography/images/1.jpg',
      width: 750,
      height: 1322,
    },
    {
      identifier: 9,
      num: 'Εικόνα 9',
      title: 'Η Προδοσία, τέμπερα, 128,5 x 95,5 εκ.',
      description: 'Αντίγραφο από τη μακέτα της Μητροπόλεως Άμφισσας.\n' +
        'Στην πολυπρόσωπη παράσταση της σκηνής, ο Χριστός εικονίζεται στο κέντρο αγκαλιασμένος από τον Ιούδα που του δίνει το φιλί της Προδοσίας. Οι δύο μορφές περιβάλλονται από πλήθος Ιουδαίων που κραδαίνουν απειλητικά, τα ρόπαλα που κρατούν. Από τη ρωμαϊκή φρουρά εικονίζονται εδώ, ελάχιστοι στρατιώτες που μοιάζουν και να μη συμμετέχουν ενεργά στο δρώμενο. Από τη σκηνή δεν λείπει και το επεισόδιο του Πέτρου, που σύμφωνα με τη ευαγγελική διήγηση του Ιωάννη, κόβει με το μαχαίρι που κρατά το αυτί του Μάλχου, υπηρέτου του Αρχιερέως. Η σύνθεση αποδίδεται σε τέμπερα, με δυναμικά και εντυπωσιακά μέσα του Παπαλουκά που δημιουργεί εδώ, μία από τις σημαντικές αποδόσεις της Προδοσίας.\n',
      position: [6, 0, 2],
      rotation: [0, -Math.PI / 5, 0],
      url: '/hagiography/images/9.jpg',
      width: 1044,
      height: 1439,
    },
    {
      identifier: 11,
      num: 'Εικόνα 11',
      title: 'Ο Παντοκράτωρ, λάδι σε χαρτί kraft, 193 x 200 εκ. Από την εκκλησία της Σχολής Νομικού. ',
      description: 'Ο Χριστός Παντοκράτωρ στο κεντρικό θέμα στη μακέτα της εκκλησίας Σχολής Νομικού. \n' +
        'Ο Χριστός παριστάνεται εδώ, σε προτομή με το ιμάτιο να εγκλωβίζει και τα δύο χέρια του μέχρι τους καρπούς. Πολύπτυχο το ιμάτιο με αποχρώσεις του πράσινου, αφήνει ακάλυπτο τον κόκκινο χιτώνα που προβάλλει στο στήθος. Με το αριστερό χέρι κρατεί ευαγγέλιο με το πάχος των σελίδων στην εσωτερική πλευρά και με το δεξιό ευλογεί. Το σχήμα είναι αρχαϊκό και προκύπτει από την αυτοκρατορική εικονογραφία, όπως στους σόλιδους του Ιουστινιανού Β΄ και του Κωνσταντίνου του Ζ΄, καθώς και στην εγκαυστική εικόνα του Χριστού της Μονής Σινά. Σύμφωνα τώρα με την εικονογραφημένη προφητεία του Ιερεμίου «ΟΥΤΟΣ Ο ΘΕΟΣ ΗΜΩΝ ΟΥ ΛΟΓΙCΘΗΣΕΤΑΙ ΕΤΕΡΟΣ ΠΡΟΣ ΑΥΤΟΝ», είναι αυτός ο τύπος που εικονογραφεί τον Χριστό. Τεχνοτροπικά η παράσταση εκπροσωπεί απόλυτα, τα πάγια πλέον ζωγραφικά μέσα του Παπαλουκά.\n',
      position: [8, 0, 4],
      rotation: [0, -Math.PI / 5, 0],
      url: '/hagiography/images/11.jpg',
      width: 1493,
      height: 1368,
    },
    {
      identifier: 10,
      num: 'Εικόνα 10',
      title: 'Η Πλατυτέρα, λάδι σε χαρτί επικολλημένο σε χαρτόνι, 18 x 50 εκ. Από την εκκλησία της Σχολής Νομικού.',
      description: 'Η Παναγία Βλαχερνίτισσα σε σχέδιο για την εκκλησία της Σχολής Νομικού.\n' +
        'Η παράσταση της Παναγίας Βλαχερνίτισσας παρακολουθεί πιστά, την ίδια μορφή που γράφεται στον κυρίως ναό της Μονής Διονυσίου. Η παράσταση αποτελεί αρχαïκό παλαιοχριστιανικό σχήμα με παράδειγμα τη Βλαχερνίτισσα του τέλους του 6ου αιώνα στο ναό της Δροσσιανής στο χωριό Μονή της Νάξου. Με εύκολα αναγνώσιμες τις νοηματικές προεκτάσεις του θέματος, δημιουργούνται αυτή την εποχή παραστάσεις με τον νήπιο Χριστόν στην αγκαλιά της μητέρας του που υποστηρίζουν την ενανθρώπιση του Χριστού. Και τούτο γιατί ήδη αυτή την εποχή η Ενσάρκωση του Χριστού αμφισβητείται αιρετικά.\n',
      position: [10, 0, 6],
      // position: [5, 2.5, 3],
      rotation: [0, -Math.PI / 4, 0],
      url: '/hagiography/images/10.jpg',
      width: 2560,
      height: 831,
    },
  ]
}