import * as THREE from 'three';
import {useEffect, useRef} from 'react';
import {useLocation, useRoute} from 'wouter';
import {GOLDEN_RATIO} from '../../config/constants';
import {useFrame} from '@react-three/fiber';
import {easing} from 'maath';
import Frame from './Frame';
import BriefingFrame from "./BriefingFrame";
import getUuid from 'uuid-by-string';
import {useStateContext} from "../../state/StateContext";

function Frames() {
  const { exhibition, cameraSpeed, setCameraSpeed,
    activeImageIndex, setActiveImageIndex, isOpenDrawer, setIsOpenDrawer } = useStateContext();
  const ref = useRef();
  const clicked = useRef();
  const [, route] = useRoute(`/${exhibition.route}/:id`);
  const [, setLocation] = useLocation();
  const images = exhibition.images;

  const quaternion = new THREE.Quaternion();
  const position = new THREE.Vector3();

  useEffect(() => {
    if (activeImageIndex !== null) setCameraSpeed(0.5);
    clicked.current = ref.current.getObjectByName(route?.id);
    if (clicked.current) {
      clicked.current.parent.updateWorldMatrix(true, true);
      /* Change look at position on click */
      clicked.current.parent.localToWorld(position.set(0, GOLDEN_RATIO / 2, 1.5));
      clicked.current.parent.getWorldQuaternion(quaternion);
    } else {
      position.set(0, 0, 5.5);
      quaternion.identity();
    }
    if (isOpenDrawer && clicked.current) {
      clicked.current.parent.localToWorld(position.set(0.5, GOLDEN_RATIO / 2, 1.75));
    }
    else if (isOpenDrawer && !clicked.current) position.set(5, 0, 6);
  });

  useFrame((state, dt) => {
    easing.damp3(state.camera.position, position, cameraSpeed, dt);
    easing.dampQ(state.camera.quaternion, quaternion, cameraSpeed, dt);
  });

  function handleClick(event) {
    const target = isText(event.object) ? event.object.parent : event.object;
    if (target.identifier === 'open' || target.identifier === 'title' || target.identifier === 'line') return;
    event.stopPropagation();

    updateActiveImageIndex(target);
    if (isOpenDrawer) setIsOpenDrawer(false);
    setLocation(clicked.current === target ? `/${exhibition.route}` : `/${exhibition.route}/` + target.name);
  }

  function updateActiveImageIndex(target) {
    if (clicked.current === target) setActiveImageIndex(null);
    else setActiveImageIndex(images.indexOf(findImageByUuid(target.name)));
  }

  function findImageByUuid(uuid) {
    return images.find((image) => getUuid(image.url) === uuid);
  }

  function isText(object) {
    return object.identifier === "text";
  }

  function isBriefingFrame(image) {
    return image.identifier === 0;
  }

  function handlePointerMissed() {
    // TODO is it better to just do nothing?
    // setLocation('/');
    // setActiveImageIndex(null);
    // setIsOpenDrawer(false);
  }


  return (
    <group
      ref={ref}
      onClick={handleClick}
      onPointerMissed={handlePointerMissed}>
      {images.map((image) => {
        if (isBriefingFrame(image)) return <BriefingFrame key={image.identifier} {...image} />
        return <Frame key={image.identifier} {...image} />
      })}
    </group>
  )
}

export default Frames;