import {useLocation} from 'wouter';
import getUuid from 'uuid-by-string';
import {colors} from '../../styles/colors';
import {useEffect, useState} from 'react';
import {useStateContext} from "../../state/StateContext";
import {Button, Box, Divider, IconButton, Typography} from "@mui/material";
import SquareSharpIcon from '@mui/icons-material/SquareSharp';
import {PRIMARY_FONT_SIZE} from "../../config/constants";

function PreviousAndNextButtons({ images }) {
  const [, setLocation] = useLocation();
  const { exhibition, activeImageIndex, setActiveImageIndex, isOpenDrawer, setIsOpenDrawer } = useStateContext();
  const [isPreviousHovered, setIsPreviousHovered] = useState(false);
  const [isNextHovered, setIsNextHovered] = useState(false);

  useEffect(() => {
    function onKeyUp({key}) {
      if (key === 'ArrowUp' || key === 'ArrowRight') document.getElementById('next').click();
      else if (key === 'ArrowLeft') document.getElementById('previous').click();
      else if (key === 'ArrowDown') document.getElementById('reset').click();
    }

    document.addEventListener('keyup', onKeyUp);
    return () => document.removeEventListener('keyup', onKeyUp);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function moveToPrevious() {
    let newActiveImageIndex;
    if (activeImageIndex === null) newActiveImageIndex = 0;
    else if (activeImageIndex === undefined) newActiveImageIndex = 0;
    else newActiveImageIndex = activeImageIndex - 1;
    if (newActiveImageIndex < 0) newActiveImageIndex = images.length - 1;

    const newActiveImage = images[newActiveImageIndex];
    setLocation(`/${exhibition.route}/` + getUuid(newActiveImage.url));
    setActiveImageIndex(newActiveImageIndex);
    setIsOpenDrawer(false);
  }

  function moveToNext() {
    let newActiveImageIndex;
    if (activeImageIndex === null) newActiveImageIndex = 0;
    else if (activeImageIndex === undefined) newActiveImageIndex = 0;
    else newActiveImageIndex = activeImageIndex + 1;
    if (newActiveImageIndex >= images.length) newActiveImageIndex = 0;

    const newActiveImage = images[newActiveImageIndex];
    setLocation(`/${exhibition.route}/` + getUuid(newActiveImage.url));
    setActiveImageIndex(newActiveImageIndex);
    setIsOpenDrawer(false);
  }

  function reset() {
    setLocation(`/${exhibition.route}`);
    setActiveImageIndex(null);
    setIsOpenDrawer(false);
  }


  return (
    <div style={{display: isOpenDrawer ? 'none' : 'inline'}}>
      <Box sx={{position: "absolute", bottom: 30, left: "38%"}}>
        <Button id="previous" onClick={moveToPrevious} disableRipple
                onMouseEnter={() => setIsPreviousHovered(true)}
                onMouseLeave={() => setIsPreviousHovered(false)}
                sx={{
                  px: 0.25, m: 0, pb: 0.25,
                  fontSize: PRIMARY_FONT_SIZE,
                  color: colors.white,
                  backgroundColor: 'transparent',
                  '&.MuiButtonBase-root:hover': {
                    bgcolor: 'transparent',
                  },
                }}>
          {'προηγούμενο'}
        </Button>
        <Divider style={{ width: isPreviousHovered ? '95%' : '0', transition: 'width 0.4s ease',
          transform: 'translate(-50%, 0%)'}}
                 sx={{backgroundColor: colors.white, ml: '50%'}}/>
      </Box>

      <IconButton id="reset" onClick={reset} disableRipple
                  sx={{
                    position: 'fixed',
                    bottom: 31.55,
                    left: '50%',
                    transform: 'translateX(-50%)', // Horizontally center the container
                    backgroundColor: 'transparent',
                    '&.MuiButtonBase-root:hover': {
                      bgcolor: 'transparent',
                    },
                  }}>
        <SquareSharpIcon style={{transform: 'scale(4, 1)', transition: 'color 0.4s ease'}}
                         sx={{color: colors.white, fontSize: 11.5, "&:hover": { color: colors.ecru}}}/>
      </IconButton>

      <Divider
        style={{
          width: isNextHovered ? '90%' : '0',
          transition: 'width 0.4s ease',
          transform: 'translate(-50%, 0%)'}}
        sx={{backgroundColor: colors.white, ml: '50%'}}/>

      <Box textAlign="end" sx={{position: "absolute", bottom: 30, right: "40%"}}>
        <Button id="next" onClick={moveToNext} disableRipple
                onMouseEnter={() => setIsNextHovered(true)}
                onMouseLeave={() => setIsNextHovered(false)}
                sx={{
                  px: 0.25, m: 0, pb: 0.25,
                  fontSize: PRIMARY_FONT_SIZE,
                  color: colors.white,
                  backgroundColor: 'transparent',
                  '&.MuiButtonBase-root:hover': {
                    bgcolor: 'transparent',
                  },
                }}>
          <Typography variant="title" color="inherit" noWrap>
            {'επόμενο'}
          </Typography>
        </Button>
        <Divider
          style={{
            width: isNextHovered ? '90%' : '0',
            transition: 'width 0.4s ease',
            transform: 'translate(-50%, 0%)'}}
          sx={{backgroundColor: colors.white, ml: '50%'}}/>
      </Box>

    </div>
  )
}

export default PreviousAndNextButtons;